<template>
  <transition name="slide-fade">
    <div v-if="isActive" class="sidebar-menu-card">
      <button
        @click="$emit('closeMenu')"
        class="sidebar-menu-card-close-btn"
      ></button>
      <BaseMapSideBarSocialBtn />
    </div>
  </transition>
  <transition name="fade">
    <div
      v-if="isActive"
      class="sidebar-container"
      @click="$emit('closeMenu')"
    ></div>
  </transition>
</template>

<script>
import BaseMapSideBarSocialBtn from "./BaseMapSideBarSocialBtn.vue";
export default {
  emits: ["closeMenu"],
  props: ["isActive"],
  components: { BaseMapSideBarSocialBtn },
};
</script>

<style lang="scss">
.slide-fade {
  &-enter-active {
    transition: all 0.1s ease-out;
  }
  &-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
  }
  &-enter-from,
  &-leave-to {
    transform: translateX(-100%);
  }
}
.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity 0.3s ease;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
.sidebar-container {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100000;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  transition: 0.3s all;
}
.sidebar-menu-card {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100001;
  background: #fff;
  width: 350px;
  height: 100%;
  transition: 0.3s all;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-close-btn {
    border: none;
    outline: none;
    padding: 12px 20px;
    cursor: pointer;
    background-color: #fff;
    background-position: center;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-image: url("../assets/close.png");
  }
}

@media (max-width: 576px) {
  .sidebar-menu-card {
    width: 80%;
  }
}
</style>
