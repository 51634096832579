npm run<template>
  <BaseMap />
</template>

<script>
import BaseMap from "./components/BaseMap.vue";

export default {
  name: "App",
  components: {
    BaseMap,
  },
};
</script>

<style>
@font-face {
    font-family: 'poppins';
    src: url('./assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
         url('./assets/fonts/poppins-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;

}


@font-face {
    font-family: 'poppins';
    src: url('./assets/fonts/poppins-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/poppins-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;

}

* {
  font-family: "Poppins", sans-serif !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media (max-width: 289px) {
  *{
    font-size: 12px;
  }
}
</style>
