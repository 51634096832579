<template>
  <div class="preloader-wrapper">
    <div class="preloader-card">
      <img class="preloader-card-img" src="../assets/card-img.png" alt="" />
      <div class="preloader-card-body">
        <p>
          На цій мапі ми розрахували наскільки кожна ділянка Києва сприятлива
          для проживання сім'ї з дитиною.
        </p>

        <p>
          Загальна оцінка варіюється від 0 до 100 балів і відображається
          кольоровою шкалою.
        </p>

        <p>
          Натиснувши на конкретну ділянку ви побачите оцінку по групам факторів.
        </p>
      </div>
      <div class="button-wrapper">
        <button @click="$emit('showMap')" class="button button-primary">
          Перейти до мапи
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["showMap"],
  props: { mapLoaded: Boolean, visited: Boolean },
};
</script>

<style lang="scss">
.preloader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  position: absolute;
  width: 100%;
  height: 100%;
  background: no-repeat center / cover url("../assets/bg.png");
  backdrop-filter: blur(1.15rem);
}
.preloader-card {
  background: #fff;
  width: 450px;
  padding: 28px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px;
  border-radius: 4px;
  &-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 300px;
    width: 300px;
  }
  &-body {
    padding-bottom: 10px;
  }
  .button-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .button {
    margin: 0 auto;
    padding: 0 25px;
    height: 42px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: 0.3s all;
    box-shadow: rgb(0 0 0 / 15%) 0px 2px 4px;
    &-primary {
      background: rgba(24, 160, 251, 1);
      font-weight: bold;
      color: #fff;
      &:hover {
        background: rgba(24, 160, 251, 1);
        box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;
      }
    }
  }
}
@media (max-width: 576px) {
  .preloader-wrapper {
    background: #fff;
  }
  .preloader-card {
    box-shadow: none;
    .button-primary {
      width: 100%;
    }
  }
}
</style>
