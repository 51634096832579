<template>
  <div class="map-legend-wrapper">
    <BaseMapInfoCard
      @card-height="$emit('cardHeight', $event)"
      @close-card="$emit('closeCard')"
      :show="show"
      :get-data="getData"
    />

    <div class="map-legend-card">
      <div class="map-legend-container">
        <div><img class="emiji" src="../assets/sad.png" alt="sad" /></div>
        <Slider
          class="slider"
          :min="1"
          :max="100"
          :step="10"
          :tooltips="false"
          v-model="value"
        />
        <div>
          <img class="emiji" src="../assets/positive.png" alt="in-love" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// slider component from npm
import Slider from "@vueform/slider";
import BaseMapInfoCard from "./BaseMapInfoCard";
import "@vueform/slider/themes/default.css";

export default {
  emits: ["changeFilter", "closeCard", "cardHeight"],
  props: ["show", "getData"],
  components: { Slider, BaseMapInfoCard },
  data() {
    return { value: [0, 100] };
  },
  watch: {
    value() {
      this.$emit("changeFilter", this.value);
    },
  },
};
</script>

<style lang="scss">
.map-legend {
  &-wrapper {
    position: absolute;
    width: 35em;
    bottom: 0;
    left: 0;
  }
  &-card {
    z-index: 99;
    background: #fff;
    margin: 10px;
    padding: 15px;
    border-radius: 12px;
    transition: 0.3s;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 0.1);
  }
  &-container {
    width: 100%;
    display: flex;
    .slider {
      flex-grow: 1;
      transform: translate(0, 10px);
      padding: 0 15px 0 15px;
    }
    .emiji {
      padding: auto;
      height: 28px;
      width: 28px;
    }
  }
}
.slider-connects {
  background: linear-gradient(
    90deg,
    rgb(255, 34, 38) 0%,
    rgb(255, 255, 37) 50%,
    #1fcf57 100%
  );
}
:root {
  --slider-connect-bg: rgb(0 0 0 / 0);
  --slider-tooltip-bg: #fff;
  --slider-tooltip-color: #3b3b3b;

  --slider-handle-ring-color: #3b82f630;
  --slider-height: 12px;
}
@media (max-width: 576px) {
  .map-legend {
    &-wrapper {
      width: 100%;
    }
    &-card {
      padding: 15px;
      overflow: hidden;
      margin: 0px;
      border-radius: 0px;
    }
    &-container {
      .emiji {
        height: 24px;
        width: 24px;
      }
      .slider {
        flex-grow: 8;
        transform: translate(0, 8px);
        padding: 0 1em 0 1em;
      }
    }
  }
}
</style>
