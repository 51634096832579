<template>
  <div class="preloader-wrapper">
    <div class="loading-container">
      <h1><img src="../assets/preloader.gif" alt="" /></h1>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["showMap"],
};
</script>

<style lang="scss" scoped>
.preloader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 100000;
  position: absolute;
  width: 100%;
  height: 100%;
}
.preloader-card {
  width: 450px;
  padding: 28px;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px;
  border-radius: 4px;
  &-img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 300px;
    width: 300px;
  }
  &-body {
    padding-bottom: 10px;
  }
}
</style>
