<template>
  <div class="map-sub-btn">
    <div @click="openFBPopup" class="map-sub-btn-item btn-share"></div>
    <div @click="isOpen = !isOpen" class="map-sub-btn-item btn-info"></div>
    <div id="chat-btn" class="map-sub-btn-item btn-chat"></div>
  </div>
  <transition name="slide-fade">
    <TheInfoPanel @close-info="isOpen = !isOpen" :open-info="isOpen" />
  </transition>
</template>

<script>
import TheInfoPanel from "./TheInfoPanel.vue";

export default {
  components: {
    TheInfoPanel,
  },
  mounted() {
    this.insertBX24Form("chat-btn");
  },
  data() {
    return { isOpen: false };
  },
  methods: {
    insertBX24Form(el) {
      let newElement = document.createElement("script");
      newElement.setAttribute("data-b24-form", "click/208/fafix2");
      newElement.setAttribute("data-skip-moving", "true");
      newElement.innerHTML = `
(function(w,d,u){
var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/180000|0);
var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
})(window,document,'https://cdn.bitrix24.ua/b8363441/crm/form/loader_208.js');`;
      let chat_btn = document.getElementById(el);
      chat_btn.before(newElement);
    },
    openFBPopup() {
      const width = document.body.clientWidth;
      document.open(
        "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fchildhood.m4u.com.ua/%2F&amp;src=sdkpreparse",
        "popup",
        `width=600,height=600,left=${width / 2 - 300}`
      );
    },
  },
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(100%);
}

.map-sub-btn {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: 0;
  bottom: 0;
  padding: 10px 10px 25px 10px;
  width: 62px;
  z-index: 1;

  &-item {
    position: relative;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 0.1);
    height: 42px;
    width: 42px;
    border-radius: 12px;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .btn-share {
    background: #fff url("../assets/btn-icons/share-icon.svg") no-repeat 50% 50%;

    &:before {
      content: "";
      display: block;
      position: absolute;
      background: #fff;
      color: #1f1f1f;
      right: 52px;
      width: 0px;
      text-align: center;
      font-weight: 400;
      font-size: 0;
      padding: 22px 0 12px 0;
      border-radius: 0;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
    }

    &:hover {
      &:before {
        content: "Поділитися картою";
        display: block;
        width: 150px;
        font-weight: 400;
        font-size: 14px;
        padding: 12px 5px 12px 5px;
        border-radius: 12px;
      }
    }
  }

  .btn-info {
    background: #fff url("../assets/btn-icons/info-icon.svg") no-repeat 50% 50%;

    &:before {
      content: "";
      display: block;
      position: absolute;
      background: #fff;
      color: #1f1f1f;
      right: 52px;
      width: 0px;
      text-align: center;
      font-weight: 400;
      font-size: 0;
      padding: 12px 0 12px 0;
      border-radius: 0;
      transition: all 0.2s;
    }

    &:hover {
      &:before {
        content: "Про карту";
        display: block;
        right: 52px;
        width: 150px;
        font-size: 14px;
        padding: 12px 5px 12px 5px;
        border-radius: 12px;
      }
    }
  }

  .btn-chat {
    background: #1f1f1f url("../assets/btn-icons/chat-icon.svg") no-repeat 50%
      50%;
    transition: all 0.2s;

    &:before {
      content: "";
      display: block;
      position: absolute;
      background: #1f1f1f;
      color: #ffffff;
      right: 52px;
      width: 0px;
      text-align: center;
      font-weight: 400;
      font-size: 0;
      padding: 12px 0 12px 0;
      border-radius: 0;
      transition: all 0.2s;
    }

    &:hover {
      background: rgba(24, 160, 251, 1) url("../assets/btn-icons/chat-icon.svg") no-repeat 50%
        50%;

      &:before {
        content: "Зворотній зв'язок";
        display: block;
        width: 150px;
        background: rgba(24, 160, 251, 1);
        font-size: 14px;
        padding: 12px 5px 12px 5px;
        border-radius: 12px;
      }
    }
  }
}

@media (max-width: 576px) {
  .map-sub-btn {
    top: 0;
    left: 0;
    flex-direction: column-reverse;
    height: 149px;
    padding: 10px;

    &-item {
      width: 38px;
      height: 38px;
    }
    .btn-info {
      &:hover {
        &:before {
          display: none;
        }
      }
    }
    .btn-share {
      &:hover {
        &:before {
          display: none;
        }
      }
    }
    .btn-chat {
      &:before {
        left: 48px;
        height: 38px;
        padding: 10px 0 10px 0;
      }
      &:hover {
        &:before {
          display: none;
        }
      }
    }
  }
}
</style>
