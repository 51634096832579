<template>
  <div class="logo-link">
    <a href="https://m4u.ua/" target="_blank"
      ><img class="logo-link-ico" :src="require('../assets/m4u-logo.png')" alt="home"
    /></a>
  </div>
</template>


<style lang="scss">
.logo-link {
  position: absolute;
  left: 10px;
  top: 10px;
  height: 56px;

  &-ico {
    height: 56px;
    width: auto;
    transition: 0.2s all;
    opacity: .7;

    &:hover {
      opacity: 1;
    }
  }
}
@media (max-width: 576px) {
  .logo-link {
    z-index: 0;
    position: absolute;
    left: 10px;
    bottom: 44px;
    top: auto;
    &-ico {
      height: 35px;
      width: auto;
      opacity: 1;
    }
  }
}
</style>
