<template>
  <div class="social-container">
    <h3>Слідкуйте за новинами:</h3>
    <ul class="social-list">
      <li v-for="el in mediaLinks" :key="el.id">
        <a target="_blank" :href="el.url">
          <img :src="require('../assets/social-btn/' + el.img)" :alt="el.alias"
        /></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mediaLinks: [
        {
          id: 1,
          url: "https://www.facebook.com/m4u.agency",
          img: "logos_facebook.png",
          alias: "facebook",
        },
        {
          id: 2,
          url: "https://www.linkedin.com/company/m4uestate/mycompany/",
          img: "logos_linkedin-icon.png",
          alias: "linkedin",
        },
        {
          id: 3,
          url: "https://m4u.ua",
          img: "m4u-webpage.png",
          alias: "webpage",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
h3{
  font-weight: 500;
}
.social {
  &-container {
    width: 100%;
    background: #fff;
    padding: 5px 12px;
  }
  &-list {
    display: flex;
    li {
      margin-right: 10px;
      padding: 0;
      img {
        width: 42px;
        height: 42px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
