<template>
  <div id="map"></div>
  <TheLogo />
  <TheControleBtn />
  <BaseMapSideBar :is-active="showMenu" @close-menu="showMenu = false" />
  <BaseMapGreetingsCard v-if="!visited" @showMap="onShowMap()" />
  <BaseMapPreloader v-if="visited && !mapLoaded" />

  <BaseMapLegend
    @card-height="curCardHeight = $event"
    @close-card="closeInfoCard()"
    :show="showCard"
    :get-data="prop"
    @change-filter="setNewFilter"
  />
</template>

<script>
import layerStiile from "./geodata/layerStiile.json";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import BaseMapGreetingsCard from "./BaseMapGreetingsCard.vue";
import BaseMapPreloader from "./BaseMapPreloader.vue";
import BaseMapLegend from "./BaseMapLegend.vue";
import BaseMapSideBar from "./BaseMapSideBar.vue";
import TheLogo from "./TheLogo.vue";
import TheControleBtn from "./TheControleBtn.vue";
export default {
  name: "BaseMap",
  components: {
    TheControleBtn,
    TheLogo,
    BaseMapLegend,
    BaseMapPreloader,
    BaseMapGreetingsCard,
    BaseMapSideBar,
  },
  beforeMount() {
    if (
      document.cookie.replace(
        /(?:(?:^|.*;\s*)visited\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      )
    ) {
      this.visited = true;
    }
  },

  mounted() {
    this.createMap();
  },
  data() {
    return {
      curCardHeight: 225,
      showMenu: false,
      map: {},
      layers: {},
      // curZoomLevel: 12,
      firstSymbolId: null,
      marker: {},
      grid3d: layerStiile,
      visited: false,
      mapLoaded: false,
      showCard: false,
      prop: null,
      mintHeight: 35,
      maxHeight: 240,
      curHeight: 35,
    };
  },
  methods: {
    closeInfoCard() {
      this.showCard = false;
      this.marker.setLngLat([0, 0]).addTo(this.map);
    },
    onShowMap() {
      document.cookie = "visited=1;max-age=36000000";
      this.visited = true;
    },
    createMap() {
      this.map = new maplibregl.Map({
        container: "map",
        style:
          "https://api.maptiler.com/maps/32058f8f-bb77-4d07-af28-38efccbac236/style.json?key=ZPfVXp6QlJgQVyHvE9s3",
        center: [30.53101, 50.448353],
        zoom: 12,
        maxZoom: 15.9,
        minZoom: 10.1,
        attributionControl: false,
        hash: true,
      });

      this.map.addControl(
        new maplibregl.AttributionControl({
          customAttribution: [
            "<a href='https://www.cityscale.com.ua/index.htm'>CityScale</a>",
            "<a href='http://www.m4u.ua/'>© M4U</a>",
          ],
        })
      );
      this.map.on("load", () => {
        this.map.addSource("grid3d", {
          type: "vector",
          tiles: [
            "https://api.maptiler.com/tiles/b2f3d612-736d-4a6f-b053-81e232ce8cdb/{z}/{x}/{y}.pbf?key=ZPfVXp6QlJgQVyHvE9s3",
          ],
        });

        // CAstom map marker
        const el = document.createElement("div");
        el.id = "marker";

        this.marker = new maplibregl.Marker(el);
        this.addlayer(this.grid3d, "water_name_line");
      });

      // click events
      this.map.on("click", "grid3d", (e) => {
        let center = [e.lngLat.lng, e.lngLat.lat];

        this.prop = e.features[0].properties
          ? e.features[0].properties
          : this.noData;
        this.showCard = true;
        this.marker.setLngLat([e.lngLat.lng, e.lngLat.lat]).addTo(this.map);
        if (document.body.clientWidth <= 576) {
          if (this.curCardHeight > 100) {
            center = [
              e.lngLat.lng,
              e.lngLat.lat - (this.map.getBounds()._ne.lat - e.lngLat.lat) / 3,
            ];
          }
          this.map.flyTo({
            center: center,
            speed: 0.3,
            curve: 0,
          });
          // console.log( (this.map.getBounds()._ne.lat - e.lngLat.lat)/2)
        }
      });
      this.map.on("mouseenter", "grid3d", () => {
        this.map.getCanvas().style.cursor = "pointer";
      });
      this.map.on("mouseleave", "grid3d", () => {
        this.map.getCanvas().style.cursor = "";
      });
    },
    addlayer(layer, filter) {
      let layers = this.map.getStyle().layers;
      let firstSymbolId;
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].type === "symbol" && layers[i].id == filter) {
          firstSymbolId = layers[i].id;
          break;
        }
      }
      this.map
        .addLayer(layer, firstSymbolId)
        .setFilter(layer.id, [
          "all",
          ["<=", ["to-number", ["get", "SCORE"]], 100],
          [">=", ["to-number", ["get", "SCORE"]], 0],
        ]);
      this.mapLoaded = true;
    },
    goToLoc(coords) {
      this.map.easeTo({ center: [coords[0], coords[1]], zoom: 15 });
      this.showCard = false;
    },
    setNewFilter(newValue) {
      this.map.setFilter("grid3d", [
        "all",
        ["<=", ["to-number", ["get", "SCORE"]], newValue[1]],
        [">=", ["to-number", ["get", "SCORE"]], newValue[0]],
      ]);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/normalize.scss";

.btn-wrapper {
  position: absolute;
  right: 10px;
  bottom: 40px;
}
@media (max-width: 576px) {
  .maplibregl-ctrl-bottom-right {
    bottom: 60px;
    z-index: 0;
  }
  .maplibregl-compact-show {
    width: 80%;
  }
}
body {
  margin: 0;
  padding: 0;
  font: 1em "Roboto";
  color: #3b3b3b;
}
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
#marker {
  background: url("../assets/marker.png");
  background-size: 100%;
  background-repeat: no-repeat;
  width: 32px;
  height: 82px;
}
* {
  box-sizing: border-box;
}
</style>
