<template>
  <div v-if="openInfo" id="info-panel" class="info-panel">
    <button @click="$emit('closeInfo')" class="close-info"></button>
    <div class="contentent-info">
      <h2>Що і як рахувалось?</h2>
      <p>
        На цій мапі ми розрахували наскільки кожна ділянка Києва сприятлива для
        проживання сім'ї з дитиною. Загальна оцінка варіюється від 0 до 100
        балів і відображається кольоровою шкалою.
      </p>
      <p>
        На мапі оцінюються ділянки, а не конкретні будинки. Територія Києва
        ділиться на рівномірні ділянки квадратної форми розміром 50 м * 50 м. В
        розрахунок беруться тільки ті ділянки, в пішій доступності від яких
        наявні об’єкти соціальної інфраструктури.
      </p>
      <p>
        Під пішою доступністю мається на увазі ізохрона, побудована на
        відстанях, що може пройти людина від центру ділянки за 20 хв.
      </p>
      <p>
        Оцінка більшості факторів логарифмується, тобто вагомість кожної
        наступної одиниці фактору має менше значення, ніж попередньої одиниці.
        (Приклад, наявність хоча б однієї аптеки дуже важлива, наявність 2-ї –
        добре, наявність 3-ї – вже не так критична і т.д., ефект насичення).
      </p>
      <p>
        Для розрахунку підсумкової оцінки ділянки використовувалась регресійна
        модель, що навчалась на даних, зібраних за допомогою фокус-групи.
        Учасники фокус-групи оцінювали за 5-ти бальною шкалою довільні ділянки
        Києва та області на основі власного досвіду на предмет комфортності
        проживання з дитиною в обраній локації.
      </p>
      <br />
      <h2>Які саме фактори враховуються в кожній групі і як?</h2>
      <h3><b> Розділ </b>«Розваги»:</h3>
      <p>
        Аквапарки, атракціони, басейни, ботанічні сади, дельфінарії, зоопарки,
        кінотеатри, ковзанки, мотузкові парки, парки для водних видів спорту,
        пляжі, ролердроми, скейт-парки, цирки.
      </p>
      <p>
        В даному розділі враховується кількість одиниць кожного фактору в пішій
        доступності. Всі значення факторів даного розділу логарифмуються.
      </p>

      <h3><b> Розділ </b> «Освіта»:</h3>
      <p>
        Дитячі садочки та школи. В даному розділі враховується кількість одиниць
        кожного фактору в пішій доступності. Всі значення факторів даного
        розділу логарифмуються.
      </p>
      <h3><b> Розділ </b> «Медицина»:</h3>
      <p>
        Аптеки та медичні заклади. В даному розділі враховується кількість одиниць
        кожного фактору в пішій доступності. Всі значення факторів даного
        розділу логарифмуються.
      </p>
      <h3><b> Розділ </b> «Озеленення»:</h3>
      <p>
        <ul>
          <li>парк – наявність в пішій доступності;</li>
          <li>відсоток зеленої території в радіусі 500 м.</li>

        </ul>
      </p>
      <h3><b> Розділ </b> «Придатність» :</h3>
      <p>
        <ul>
          <li>несприятливі заклади (в’язниці, диспансери) – кількість одиниць в пішій, значення фактору логарифмуються; </li>
          <li>шосе, залізна дорога – відстань від ділянки, значення фактору обернено пропорційне відстані;</li>
          <li>відсоток території промислового призначення в радіусі 500 м.</li>
        </ul>
      </p>
      <h3><b> Розділ </b> «Безпека» :</h3>

      <p>
        В даному розділі враховується кількість випадків злочинів (грабіж,
        розбій, хуліганство) в радіусі 500 м від центру ділянки. <br> Значення
        фактору розраховується як зважена (відповідно тяжкості) сума кількості
        випадків.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["closeInfo"],
  props: ["openInfo"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.info-panel {
  display: block;
  bottom: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 550px;
  background: #fff;
  z-index: 1000;
  transition: all 0.3s;
}

.close-info {
  position: absolute;
  top: 12px;
  right: 23px;
  padding: 0;
  font-size: 18px;
  color: rgb(255, 255, 255);
  display: block;
  border-radius: 100%;
  border: 0;
  opacity: 0.5;
  background: rgba(24, 160, 251, .7);
  width: 28px;
  height: 28px;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    color: #fff;
    opacity: 1;
    background: rgba(24, 160, 251, 1);
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 15px;
    background-color: #fff !important;
    transform: translate(-50%, -50%) rotate(135deg);
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 15px;
    background-color: #fff !important;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.contentent-info {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 14px 31px;
  font-size: 16px;
  b {
    text-transform: uppercase;
  }
}
@media (max-width: 576px) {
  .info-panel {
    position: absolute;
    width: 100%;
  }
}
</style>
