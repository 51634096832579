<template>
  <transition name="fade">
    <div
      v-show="cardShow"
      class="map-legend-card"
      :style="{ height: curHeight + 'px' }"
    >
      <div
        @touchstart="getTouchStartPos($event)"
        @touchmove.prevent="getTouchNewPos($event)"
        @touchend.prevent="switchCardHeight()"
        class="card-mobile-control"
      >
        <div
          class="card-mobile-control-icon"
          :class="[curHeight !== maxHeight ? 'collapsed' : '']"
        ></div>
      </div>
      <div
        @click="this.$emit('closeCard')"
        class="map-legend-card-close-btn"
      ></div>
      <div class="map-legend-card-body">
        <div class="map-legend-card-list">
          <div
            v-for="(value, name, index) in validatedData"
            :key="index"
            class="map-legend-card-list-el"
          >
            <div class="map-legend-card-list-el-header">
              <p>{{ headers[name] }}</p>
              <small>{{ value }}/100</small>
            </div>

            <div class="progress-bar">
              <div
                :style="{ width: value + '%' }"
                class="progress-bar-line pb-regular"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  mounted() {
    if (document.body.clientWidth <= 576) {
      this.curHeight = this.maxHeight;
    }
  },
  beforeUpdate() {
    if (document.body.clientWidth > 576) {
      this.curHeight = this.maxHeight;
    }
  },
  props: ["show", "getData"],
  emits: ["closeCard", "cardHeight"],
  data() {
    return {
      collapsed: this.show,
      headers: {
        CONTRARIETY: "Придатність",
        CRIME: "Безпека",
        EDUCATION: "Освіта",
        ENTERTAINMENT: "Розваги",
        MEDICINE: "Медицина",
        RECREATION: "Озеленнення",
      },

      mintHeight: 45,
      maxHeight: 240,
      curHeight: 45,
      toucPos: [],
    };
  },
  methods: {
    getTouchStartPos(e) {
      this.toucPos[0] = e.touches[0].clientY;
    },
    switchCardHeight() {
      if (this.toucPos[0] - this.toucPos[1] > this.mintHeight) {
        this.curHeight = this.maxHeight;
      }
      if (this.toucPos[0] - this.toucPos[1] < -1) {
        this.curHeight = this.mintHeight;
      }
      if (!this.toucPos[1]) {
        if (this.curHeight == this.mintHeight) {
          this.curHeight = this.maxHeight;
        } else {
          this.curHeight = this.mintHeight;
        }
      }
      this.toucPos = [];
      this.$emit("cardHeight", this.curHeight);
    },
    getTouchNewPos(e) {
      this.toucPos[1] = e.touches[0].clientY;
    },
  },
  computed: {
    validatedData() {
      let newData = this.getData;
      if (newData) {
        for (let iter of ["id", "fid", "SCORE"]) {
          delete newData[iter];
        }
        newData["CRIME"] = 100 - newData["CRIME"];
        newData["CONTRARIETY"] = 100 - newData["CONTRARIETY"];
        return newData;
      }
      return undefined;
    },
    cardShow() {
      return this.show;
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.map-legend-card-close-btn {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  height: 28px;
  margin: 10px 10px;
  background-position: center;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  background-image: url("../assets/close.png");
}
.card-mobile-control {
  display: none;
}
.map-legend-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  widows: 100%;
  &-el {
    width: 45%;
    padding: 10px;
    p {
      margin: 5px 0 15px 0;
    }
    small {
      margin: 5px 0 15px 0;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.map-legend-card-body {
  height: 100%;
  overflow: hidden;
}
.progress-bar {
  width: 100%;
  background: #f1f1f1;
  border-radius: 8px;

  &-line {
    border-radius: 12px;
    position: relative;
    height: 12px;
    transition: width 0.5s ease;
  }
  .pb-regular {
    background: rgba(24, 160, 251, 1);
  }
  .pb-danger {
    background: #f32121;
  }
}
@media (max-width: 576px) {
  .map-legend-card-list {
    &-el {
      p {
        margin: 5px 0 15px 0;
      }
      width: 45%;
      padding: 8px 0;
    }
    .progress-bar {
      &-line {
        height: 8px;
      }
    }
  }
  .map-legend-card-close-btn {
    margin: 5px;
  }
  .card-mobile-control {
    position: relative;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 32px;
    &-icon {
      opacity: 0.5;

      background-position: center;
      background-size: 42px;
      background-repeat: no-repeat;
      background-image: url("../assets/system-open-close.svg");
      width: 56px;
      height: 100%;
    }
    .collapsed {
      transform: rotate(180deg);
    }
  }
  .map-legend-card-body {
    height: 90%;
    overflow: hidden;
  }
}
</style>
